import ReactGA from "react-ga4";
import { GA4Interface } from "../features/sendMoney";
import { store } from "../Redux";

export const ga4Initialize = () => {
  let trackingId: string | undefined = process.env.REACT_APP_GA4_TRACKING_ID || store.getState().config?.launchConfig?.ga4Id;
  if (trackingId)
    ReactGA.initialize(trackingId);
}

export const sendGA4PageView = () => {
  ReactGA.send({ hitType: "pageview", page: window?.location?.pathname, title: window?.document?.title });
}

export const sendGA4Event = ({ category, action, label, value, data }: GA4Interface) => {
  ReactGA.event(action, {
    category: category,
    label: label, // optional
    value: value, // optional, must be a number
    ...data
  });
}
