import _ from "lodash";
import { IAppSettingsConfig, IBundleFavMetaData } from "../config/commonInterfaces";

export const headings = {
  ENTER_NUMBER: "ENTER_NUMBER",
  MARK_AS_FAV: "MARK_AS_FAV",
  REQUEST_SUBMITTED: "REQUEST_SUBMITTED",
  ENTER_AMOUNT: "Enter Amount",
  AMOUNT: "AMOUNT",
  TOTAL_AMOUNT: "TOTAL_AMOUNT",
  TXN_FEE: "TXN_FEE",
  SHOW_MORE: "SHOW_MORE",
  ADD_FAV_HEADING: "ADD_FAV_HEADING",
  FAV: "FAV",
  DO_ANOTHER_TRANSACTION: "DO_ANOTHER_TRANSACTION",
  CONTACT_US: "CONTACT_US",
  PHONE: "PHONE",
  EMAIL: "EMAIL",
  ADDRESS: "ADDRESS",
  FIND_US_ON: "FIND_US_ON",
  BATTERY_SAVER: "BATTERY_SAVER",
  APP_SETTINGS: "APP_SETTINGS",
  MENU: "MENU",
  MINS: "MINS",
  POPULAR_RECHARGES: "POPULAR_RECHARGES",
  BUY_AIRTIME: "BUY_AIRTIME",
  BUY_BUNDLES: "BUY_BUNDLES",
  BEST_SELLERS: "BEST_SELLERS",
  BUYING_BUNDLE_FOR_FOR_ALL_CAPS: "BUYING_BUNDLE_FOR_FOR_ALL_CAPS",
  VALID_FOR: "VALID_FOR",
  VALIDITY: "VALIDITY",
  COMBO_MORE: "COMBO_MORE",
  SMS: "SMS",
  NEXT: "NEXT",
  INVALID_AMOUNT: "INVALID_AMOUNT",
  PLEASE_ENTER_PREPAID_NUM: "PLEASE_ENTER_PREPAID_NUM",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  MOBILE_NUMBER: "MOBILE_NUMBER",
  BENEFITS: "BENEFITS",
  PAYABLE_AMOUNT: "PAYABLE_AMOUNT",
  BUYING_AIRTIME_FOR_ALL_CAPS: "BUYING_AIRTIME_FOR_ALL_CAPS",
  BENEFITS_AIRTIME: "BENEFITS_AIRTIME",
  PAYMENT_OPTIONS_ALL_CAPS: "PAYMENT_OPTIONS_ALL_CAPS",
  DETAILS_ALL_CAPS: "DETAILS_ALL_CAPS",
  CONTINUE_ALL_CAPS: "CONTINUE_ALL_CAPS",
  LOW_AIRTEL_MONEY_BALANCE_V2: "LOW_AIRTEL_MONEY_BALANCE_V2",
  LOW_AIRTIME_BALANCE_V2: "LOW_AIRTIME_BALANCE_V2",
  AM_BARRED_TEXT: "AM_BARRED_TEXT",
  AM_BARRED_OTHER_TEXT: "AM_BARRED_OTHER_TEXT",
  NETWORK_OPERATOR: "NETWORK_OPERATOR",
  SELF_RECHARGE_SUCCESS: "SELF_RECHARGE_SUCCESS",
  OTHERS_RECHARGE_SUCCESS: "OTHERS_RECHARGE_SUCCESS",
  IS_SUCCESSFUL: "IS_SUCCESSFUL",
  BUY_BUNDLE_RECHARGE_SUCCESS: "BUY_BUNDLE_RECHARGE_SUCCESS",
  OTP_EXPIRED: "OTP_EXPIRED",
  AMOUNT_CANT_EXCEED: "AMOUNT_CANT_EXCEED",
  AMOUNT_SHOULD_BE_GREATER_EQ: "AMOUNT_SHOULD_BE_GREATER_EQ",
  AGENT_CODE: "AGENT_CODE",
  AGENT_NAME: "AGENT_NAME",
  ADDITIONAL_PAY_METHOD: "ADDITIONAL_PAY_METHOD",
  POWERED_BY: "POWERED_BY",
  ENTER_NUMBER_HERE: "ENTER_NUMBER_HERE",
  ENTER_PHONE_NUMBER: "ENTER_PHONE_NUMBER",
  REMAINING_TIME: "REMAINING_TIME",
  DEAR_CUSTOMER_MPESA: "DEAR_CUSTOMER_MPESA",
  YOUR_TXN_IN_PROGRESS: "YOUR_TXN_IN_PROGRESS",
  REQUEST_TIMEDOUT: "REQUEST_TIMEDOUT",
  USSD_PUSH_TIMEDOUT: "USSD_PUSH_TIMEDOUT",
  AM_BALANCE: "AM_BALANCE",
  NOT_MPESA_USER: "NOT_MPESA_USER",
  ARE_YOU_SURE: "ARE_YOU_SURE",
  NO: "NO",
  YES: "YES",
  PLEASE_ENTER_VALID_TILL: "PLEASE_ENTER_VALID_TILL",
  TRANSACTION_LIM_BREACHED: "TRANSACTION_LIM_BREACHED",
  DOWNLOAD_MY_AIRTEL: "DOWNLOAD_MY_AIRTEL",
  TO_ENJOY_ENHANCED_LIM: "TO_ENJOY_ENHANCED_LIM",
  PROCEED_ALL_CAPS: "PROCEED_ALL_CAPS",
  CHANGE_AM_PIN_TEXT: 'CHANGE_AM_PIN_TEXT',
  CHANGE_PIN_SUCCESS_TEXT: 'CHANGE_PIN_SUCCESS_TEXT',
  RESET_TEMP_PIN_DESCRIPTION: 'RESET_TEMP_PIN_DESCRIPTION',
  RESET_PIN_DESCRIPTION: 'RESET_PIN_DESCRIPTION',
  SET_QUESTION_DESCRIPTION: 'SET_QUESTION_DESCRIPTION',
  SET_PIN_DESCRIPTION: 'SET_PIN_DESCRIPTION',
  SET_PIN: 'SET_PIN',
  SET_QUESTION: 'SET_QUESTION',
  SET_PIN_SUCCESS_HEADING: 'SET_PIN_SUCCESS_HEADING',
  SET_PIN_SUCCESS_DESCRIPTION: 'SET_PIN_SUCCESS_DESCRIPTION',
  AIRTEL_MONEY_ALL_CAPS: "AIRTEL_MONEY_ALL_CAPS",
  AIRTIME_ALL_CAPS: "AIRTIME_ALL_CAPS",
  SEND_OTP_ALL_CAPS: 'SEND_OTP_ALL_CAPS',
  AIRTEL_MONEY_PUSH_NOTIFICATION_TEXT: 'AIRTEL_MONEY_PUSH_NOTIFICATION_TEXT',
  RECIEVED_TEMP_PIN_TEXT: 'RECIEVED_TEMP_PIN_TEXT',
  FIRST_TIME_ENTER_PIN: 'FIRST_TIME_ENTER_PIN',
  PIN_PATTERN_MISMATCH: 'PIN_PATTERN_MISMATCH',
  PIN_MISSMATCH_ERROR: 'PIN_MISSMATCH_ERROR',
  PIN_INPUT_VALID: 'PIN_INPUT_VALID',
  CONFIRM_PIN_INPUT_DONE: 'CONFIRM_PIN_INPUT_DONE',
  CHANGE_PIN_SUCCESS: 'CHANGE_PIN_SUCCESS',
  CHANGE_PIN_FAILED: 'CHANGE_PIN_FAILED',
  NO_BUNDLE_AVAILABILITY_TEXT: "NO_BUNDLE_AVAILABILITY_TEXT",
  BUNDLE_PURCHASE_TEXT: 'BUNDLE_PURCHASE_TEXT',
  BUNDLE_NAME: 'BUNDLE_NAME',
  VALUE_IN_CURRENCY: "VALUE_IN_CURRENCY"
};

export const errors = {
  INVALID_WALLET: "INVALID_WALLET",
  LOW_AIRTEL_MONEY_BALANCE: `LOW_AIRTEL_MONEY_BALANCE`,
  TRANSACTION_LIMIT_ERROR: "TRANSACTION_LIMIT_ERROR"
};

//states
export const AMOUNT = "amount";
export const PHONE = "phone";
export const AM = "am";
export const FINAL = "final";

export const SMS = "SMS";
export const OTP_LENGTH = 4;
export const AM_PIN_LENGTH = 4;
export const ACCEPTED = "accepted";
export const PHONE_NUMBER = "phoneNumber";
export const AUTH_TOKEN = "authToken";
export const LANGUAGE = "language";
export const IS_GUEST_USER = "isGuestUser";
export const GUEST_CAP = "GUEST";
export const GUEST = "Guest";

export const states = {
  sendMoney: {
    SEND_MONEY: "send_money",
    SEND_MONEY_PHONE: "send_money_phone",
    SEND_MONEY_AMOUNT: "send_money_amount",
    SEND_MONEY_DETAILS: "send_money_details",
    SEND_MONEY_SUBMITTED: "send_money_submitted",
  }
}

export const errorCodes = {
  //require error on new page
  PROCESS_TXN_AM_PIN_NEW_ERROR_PAGE_CODES: [
    //lock
    4047,
    //lock validate pin,
    4020,
    //bar
    4051,
    //lock
    4035,
    //user barred
    4019
  ],

  //errors shown on same page
  PROCESS_TXN_AM_PIN_SIMPLE_CODES: [
    //incorrect
    4046,
    //last incorrect
    4052],
  INCORRECT_PIN_ERROR_CODE: 4177,
  INSUFFICIENT_BALANCE_ERROR_CODE: 4300,
  BUY_BUNDLE_INSUFFICIENT_BALANCE_ERROR_CODE: 4263,
  TRANSACTION_LIMIT_ERROR_CODES: 4006,
  INCORRECT_OTP_ERROR_CODE: 4265,
  EMPTY_OTP_REFERENCE_ID_ERROR_CODE: 4267,
  OTP_LIMIT_EXHAUSTED_ERROR_CODE: 4268
}

export const key = {
  ENTER: "Enter"
}

//send money payment mode
export const AIRTEL_MONEY_PAYMENT_MODE = "airtelmoney";

export const BALANCE_DT_FORMAT = "DD/MM/YYYY, h:mm a";

export const FAV_DT_FORMAT = "YYYY-MM-DDTHH:mm:ssZ"; //eg: 2023-10-05T12:46:48+05:30

export const LAST_UPDATED = (timestamp: string) => `Last updated on ${timestamp}`;
export const IPHONE = "iPhone";

export const DISMISSED = "dismissed";

export const CHECK_BAL_LOC_SOURCE = "checkBalance";

export const RECHARGE_LOC_SOURCE = "recharge_loc_source";

export const BUY_BUNDLE_LOC_SOURCE = "buy_bundle_loc_source";

export const BUNDLE_NAV_STATE = "bundle";
export const PAYMENT_STATE = "payment";
export const CHECK_BALANCE_SOURCE = "checkBalance";

export const BALANCE_TIMEOUT = 15; //In minutes
export const AM_PROFILE_TIMEOUT = 15; //In minutes
export const FETCH_BUNDLE_TIMEOUT = 4 * 60; //4 hours in minutes
export const RECENT_CONTACTS_TIMEOUT = 15; //In minutes
export const FAV_TIMEOUT = 15; //In minutes
export const RECENT_NAME_NUMBER_TIMEOUT = 4 * 60;
export const CHECK_LOB_TIMEOUT = 4 * 60;

export const RECENT_CONTACTS_TXN_TYPE_PREPAID_BUNDLE = "PREPAID_BUNDLE";
export const RECENT_CONTACTS_TXN_TYPE_PREPAID_RECHARGE = "PREPAID_RECHARGE";
export const RECENT_CONTACTS_TXN_TYPE_P2P = "P2P";

export const BUNDLE_ERROR_CODES = [4025, 4121];

export const FetchedBundleFavMetaDataKeys: Array<keyof IBundleFavMetaData> = ["bundleId", "bundleType", "bundleName", "validity"];

//fallback translation keys
export const SOME_ERR = "SOME_ERR";
export const REFRESH_NOW = "REFRESH_NOW";
export const OOPS = "OOPS";
export const OFFLINE_MESSAGE = "OFLINE_MESSAGE";
export const SUCCESS_LOGOUT = "SUCCESS_LOGOUT";
export const LOGIN = "LOGIN";
export const HE_SOME_ERR = "HE_SOME_ERR";

/**
* Hard coded translations.
* They will be overriden by fetched translations at runtime. Will be be used as is when api fails
* key: label uid
* value: label translation
*/


export const fallbackTranslations: Record<string, string> = {
  [SOME_ERR]: `Something went wrong, Please try after sometime`,
  [REFRESH_NOW]: `Refresh Now`,
  [OOPS]: `Oops`,
  [OFFLINE_MESSAGE]: `Please check your internet connection`,
  [SUCCESS_LOGOUT]: `You have successfully logged out`,
  [LOGIN]: `Login`
};

//fallback translation count
export const fallbackTranslationCount = _.size(fallbackTranslations);


export const EN = "en";
export const FR = "fr";
export const V1 = "v1";
export const V2 = "V2";

export const BAL_TYPE_AIRTIME = "AIRTIME";
export const BAL_TYPE_VOICE = "VOICE";
export const BAL_TYPE_DATA = "DATA";
export const BAL_TYPE_SMS = "SMS";
export const MINUTES_SHORT = "MINUTES_SHORT";
export const YOUR_AM_BAL = "YOUR_AM_BAL";
export const VIEW_ALL_BAL = "VIEW_ALL_BALANCES";
export const MENU = "menu";
export const checkBalance = "check_balance";
export const RECENT_TRANS = "recent_transactions";
export const BUY_BUNDLE_FOOTER = "BUY_BUNDLES";
export const LAST_UPDATED_TEXT = "LAST_UPDATED";
export const NOT_AVAILABLE_RIGHT_NOW = "NOT_AVAILABLE_RIGHT_NOW";
export const UHOH = "UHOH";
export const RETRY = "RETRY";
export const UNSUPPORTED_BROWSER = "UNSUPPORTED_BROWSER";
export const PLEASE_SWITCH_OFF_WIFI = "PLEASE_SWITCH_OFF_WIFI";
export const CONTINUE_WITH_OTHER = "CONTINUE_WITH_OTHER";
export const VIEW_AM_BALANCE = "VIEW_AM_BALANCE";

//app setting related constants

//help and support page code
export const LANGUAGE_CAPS = "LANGUAGE";
export const HELP_AND_SUPPORT = "HELP_AND_SUPPORT";
export const LOGOUT = "LOGOUT";
export const CHANGE_PIN = "CHANGE_PIN";
export const MANAGE_QUESTION = "MANAGE_QUESTION";
export const FORGOT_PIN = "FORGOT_PIN";
export const FORGOT_PIN_TEXT = "FORGOT_PIN_TEXT";
export const IS_LOGOUT_CLICKED = "IS_LOGOUT_CLICKED"

//IAppSettingsConfig empty object
export const EMPTY_APP_CONFIG: IAppSettingsConfig = {
  code: "",
  icon: "",
  type: "",
  paramObj: { pageUrl: "" },
};

//IAppSettingsConfig type possible values
export const STATIC_PAGE = "STATIC_PAGE";
export const CLICK = "CLICK";
export const CUSTOM_PAGE = "CUSTOM_PAGE";

//regexes
//match 0 used as prefix and non digits
export const REGEX_BUY_AIRTIME_AMOUNT = /(^0+(?=.+)|(\D)+)/g;

export const NO_PACK_TEXT = "NO_PACK_TEXT";
export const ACTIVE_PACKS = "ACTIVE_PACKS";
export const EXPIRY = "EXPIRY";
export const BALANCE = "BALANCE";

//alt text
export const FORWARD_ICON = "Forward Icon";

//payment option
export const PAY_OPTION_AIRTEL_MONEY = "AIRTEL_MONEY";
export const PAY_OPTION_MPESA = "MPESA";

//zero decimal
export const ZERO_DECIMAL = "0.00";

export const DATE_FORMAT = "DD MMM YYYY, h:mm a";

export const PROCESS_TXN_ERROR_CODE = [4177];

export const AM_BARRED_CODE = [4020];
export const AM_DOWN_CODE = 4187;
export const AM_NO_WALLET_CODE = 4175;

export const BUY_BUNDLE = "BUY_BUNDLE";
export const skeletonConfig = {
  BOX: 2,
  CARD_LIST: 4,
  FORM_LIST: 4
}

//txn 
export const P2P = "P2P";
export const P2PI = "P2PI";
export const CASHOUT = "CASHOUT";
export const AIRTEL = "Airtel";
export const AIRTIME_CAPS = "AIRTIME";
export const RECHARGE_SERVICE = "RECHARGE_SERVICE";
export const PREPAID_RECHARGE = "PREPAID_RECHARGE";
export const AIRTEL_MONEY = "AIRTEL_MONEY";
export const AIRTEL_MONEY_PUSH = "AIRTEL_MONEY_PUSH";
export const PREPAID_BUY_BUNDLES = "PREPAID_BUY_BUNDLES";

//action
export const SELECT_EVENT = "SELECT";
export const FAILURE_EVENT = "FAILURE";
export const SUCCESS_EVENT = "SUCCESS";
export const FAILED_EVENT = "FAILED";
export const BEST_OFFER_EVENT = "BESTOFFER";
export const INCORRECT_PIN = "INCORRECT PIN"
export const PIN_INVALID = "PIN_INVL"

export const WIFI = "wifi";
export const BROWSER = "browser";

export const BALANCE_TYPE_GUEST_VAL = {
  AIRTIME: 'xxxxx',
  VOICE: 'xxx',
  DATA: 'xxxx'
}

export const ABOUT_US = "ABOUT_US";
export const TANDC = "T_&_C";

export const ACCESS_TYPES = {
  PUBLIC: "Public",
  PROTECTED: "Protected",
  GUEST: "Guest",
};

export const COMBO_KEY = "COMBO";

export const VOICE = "Voice";
export const DATA = "Data";
export const COMBO = "Combo";
export const UNI = "UNI";

export const GA_EVENTS_TEXT = {

}

export const manageQuestionHeadings = {
  MANAGE_SECURITY_QUESTION_HEADING: "MANAGE_SECURITY_QUESTION_HEADING",
  SET_SECURITY_QUESTION_HEADING: "SET_SECURITY_QUESTION_HEADING",
  MANAGE_QUESTION_SUCCESS_TEXT: "MANAGE_QUESTION_SUCCESS_TEXT",
  MANAGE_QUESTION_DESCRIPTION: "MANAGE_QUESTION_DESCRIPTION",
  SET_QUESTION_DESCRIPTION: "SET_QUESTION_DESCRIPTION",
  MANAGE_QUESTION_PIN_TEXT: "MANAGE_QUESTION_PIN_TEXT",
  CONFIRM_PIN_TEXT: "CONFIRM_PIN_TEXT",
  SELECT_SECURITY_QUESTION: "SELECT_SECURITY_QUESTION",
  SET_QUESTION_SUCCESS_HEADING: "SET_QUESTION_SUCCESS_HEADING",
  SET_QUESTION_SUCCESS_DESCRIPTION: "SET_QUESTION_SUCCESS_DESCRIPTION",
  SET_QUESTION_ANSWER_MAX_VALIDATION_ERR: "SET_QUESTION_ANSWER_MAX_VALIDATION_ERR",
  SET_QUESTION_ANSWER_MIN_VALIDATION_ERR: "SET_QUESTION_ANSWER_MIN_VALIDATION_ERR",
  SET_QUESTION_PIN_ERROR: 4243
}

export const BETA_FEATURES = {
  RESET_PIN: 'resetPin',
  CHANGE_PIN: 'changePin',
  UNLOCK_PIN: 'unlockPin',
  LANGUAGE: 'language'
}

export const BETA_PERMISSIONS = {
  GA: 'GA',
  RESTRICTED: 'Restricted'
}

export const HE_SUPPORTED_BROWSERS = '{"Samsung Internet for Android": ">10", "chromium": ">65", "safari": ">=9", "android browser": ">3.10","Chrome": ">=30", "Miui": ">=13"}'

export const PRODUCTION = "production";
export const APPLITE = "AppLite";